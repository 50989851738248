<template>
  <v-skeleton-loader :loading="status.reading" type="article@3">
    <v-sheet v-bind="propsCompSheet" data-cy="documents-shares-index">
      <v-card-title>
        {{ $t("documentsShares.title") }}
      </v-card-title>

      <v-sheet
        v-bind="propsSheetSearchSpace"
        v-if="stateIndex === 'loading'"
        class="mb-4"
      >
        {{ $t("common.loading") }}
      </v-sheet>
      <v-sheet
        v-bind="propsSheetSearchSpace"
        v-if="stateIndex === 'noResults'"
        class="mb-4"
      >
        {{ $t("documentsShares.searchNoResults") }}
      </v-sheet>
      <div v-if="stateIndex === 'results'" class="mb-4">
        <v-list two-line>
          <v-list-item
            v-for="item in documentsShares"
            :key="item.id"
            :to="{
              name: 'documents-public-show',
              params: { token: item.token },
            }"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.url }}</v-list-item-title>
              <!-- <v-list-item-subtitle>{{ item.url }}</v-list-item-subtitle> -->
            </v-list-item-content>

            <v-list-item-action class="d-flex flex-row">
              <v-btn icon @click.prevent="clickCopyUrl(item.id)">
                <v-icon>$copy</v-icon>
              </v-btn>
              <v-btn
                icon
                :to="{
                  name: 'documents-shares-edit',
                  params: { documentId: documentId, id: item.id },
                }"
                class="ml-2"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </v-list-item-action>
            <!-- Hidden text for the copy url link -->
            <div
              style="
                position: absolute;
                bottom: 0;
                opacity: 0.0000001;
                pointer-events: none;
              "
            >
              <v-text-field
                v-bind="propsFormFields"
                readonly
                :value="item.url"
                type="text"
                :id="`urlInfo_${item.id}`"
                :ref="`urlInfo_${item.id}`"
              ></v-text-field>
            </div>
          </v-list-item>
        </v-list>
      </div>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :to="{
            name: 'documents-shares-create',
            params: { documentId: documentId },
          }"
          data-cy="button-create"
          >{{ $t("common.new") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "DocumentsSharesIndex",
  mixins: [ComponentStatus],
  props: {
    documentId: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {
    stateIndex() {
      let state = "noSearch";

      if (this.status.loading) {
        state = "loading";
      } else if (this.documentsShares.length === 0) {
        state = "noResults";
      } else {
        state = "results";
      }
      return state;
    },
    documentsShares() {
      let documentShares = this.$store.getters["documentsShares/readAll"](
        this.documentId
      );

      documentShares.forEach((share) => {
        let route = this.$router.resolve({
          name: "documents-public-show",
          params: {
            token: share.token,
          },
        });

        share.url = `${location.protocol}//${location.host}${route.href}`;
      });
      return documentShares;
    },
  },
  created() {
    this.setStatusReading();
    this.$store
      .dispatch("documentsShares/readAll", { documentId: this.documentId })
      .then((/* result */) => {
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
        this.$store.commit("status/showError");
      });
  },
  methods: {
    clickCopyUrl(itemId) {
      // The input we use as source needs to be "visible", if not the
      // select and copy command does ot work.
      let inputEl =
        this.$refs[`urlInfo_${itemId}`][0].$el.querySelector("input");
      if (inputEl) {
        inputEl.select();
        inputEl.setSelectionRange(0, 99999);
        document.execCommand("copy");
      }
    },
  },
};
</script>
