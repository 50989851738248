<template>
  <v-container v-bind="propsPageMainContainer" data-cy="documents-edit-page">
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("common.edit") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'documents-index' }"
              data-cy="button-index"
            >
              <v-icon>$pageIndex</v-icon>
            </v-btn>
          </div>
          <DocumentsEdit :objectId="objectId"></DocumentsEdit>
          <v-divider class="my-10"></v-divider>
          <DocumentsSharesIndex :documentId="objectId"></DocumentsSharesIndex>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentsEdit from "../components/DocumentsEdit.vue";
import DocumentsSharesIndex from "../../documentsShares/components/DocumentsSharesIndex.vue";

export default {
  name: "DocumentsEditPage",
  components: {
    DocumentsEdit,
    DocumentsSharesIndex,
  },
  computed: {
    // 1) Computed property to set the document id.
    objectId() {
      let objectId = "";
      if (this.$route.params.id) {
        objectId = this.$route.params.id.toString();
      }
      return objectId;
    },
  },
};
</script>
