var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-skeleton-loader',{attrs:{"loading":_vm.status.reading,"type":"article@3"}},[_c('v-sheet',_vm._b({attrs:{"data-cy":"documents-shares-index"}},'v-sheet',_vm.propsCompSheet,false),[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("documentsShares.title"))+" ")]),(_vm.stateIndex === 'loading')?_c('v-sheet',_vm._b({staticClass:"mb-4"},'v-sheet',_vm.propsSheetSearchSpace,false),[_vm._v(" "+_vm._s(_vm.$t("common.loading"))+" ")]):_vm._e(),(_vm.stateIndex === 'noResults')?_c('v-sheet',_vm._b({staticClass:"mb-4"},'v-sheet',_vm.propsSheetSearchSpace,false),[_vm._v(" "+_vm._s(_vm.$t("documentsShares.searchNoResults"))+" ")]):_vm._e(),(_vm.stateIndex === 'results')?_c('div',{staticClass:"mb-4"},[_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.documentsShares),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
            name: 'documents-public-show',
            params: { token: item.token },
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.url))])],1),_c('v-list-item-action',{staticClass:"d-flex flex-row"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.clickCopyUrl(item.id)}}},[_c('v-icon',[_vm._v("$copy")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","to":{
                name: 'documents-shares-edit',
                params: { documentId: _vm.documentId, id: item.id },
              }}},[_c('v-icon',[_vm._v("$edit")])],1)],1),_c('div',{staticStyle:{"position":"absolute","bottom":"0","opacity":"0.0000001","pointer-events":"none"}},[_c('v-text-field',_vm._b({ref:`urlInfo_${item.id}`,refInFor:true,attrs:{"readonly":"","value":item.url,"type":"text","id":`urlInfo_${item.id}`}},'v-text-field',_vm.propsFormFields,false))],1)],1)}),1)],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',_vm._b({attrs:{"to":{
          name: 'documents-shares-create',
          params: { documentId: _vm.documentId },
        },"data-cy":"button-create"}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.new")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }